import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
];

export const tourRoutes = [
  {
    key: "tours",
    path: `${APP_PREFIX_PATH}/tours`,
    component: React.lazy(() => import("views/app-views/apps/tours")),
  },
  {
    key: "tour-list",
    path: `${APP_PREFIX_PATH}/apps/tours/tour-list`,
    component: React.lazy(() => import("views/app-views/apps/tours/tour-list")),
  },
  {
    key: "add-tour",
    path: `${APP_PREFIX_PATH}/apps/tours/add-tour`,
    component: React.lazy(() => import("views/app-views/apps/tours/add-tour")),
  },
  {
    key: "edit-tour",
    path: `${APP_PREFIX_PATH}/apps/tours/edit-tour/:tourId`,
    component: React.lazy(() => import("views/app-views/apps/tours/edit-tour")),
  },
];

export const hotelRoutes = [
  {
    key: "hotels",
    path: `${APP_PREFIX_PATH}/hotels`,
    component: React.lazy(() => import("views/app-views/apps/hotels")),
  },
  {
    key: "hotel-list",
    path: `${APP_PREFIX_PATH}/apps/hotels/hotel-list`,
    component: React.lazy(() =>
      import("views/app-views/apps/hotels/hotel-list")
    ),
  },
  {
    key: "add-hotel",
    path: `${APP_PREFIX_PATH}/apps/hotels/add-hotel`,
    component: React.lazy(() =>
      import("views/app-views/apps/hotels/add-hotel")
    ),
  },
  {
    key: "edit-hotel",
    path: `${APP_PREFIX_PATH}/apps/hotels/edit-hotel/:hotelId`,
    component: React.lazy(() =>
      import("views/app-views/apps/hotels/edit-hotel")
    ),
  },
];

export const restaurantRoutes = [
  {
    key: "add-restaurant",
    path: `${APP_PREFIX_PATH}/apps/restaurant/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/restaurant/add-restaurant")
    ),
  },
  {
    key: "edit-restaurant",
    path: `${APP_PREFIX_PATH}/apps/restaurant/edit/:restaurantId`,
    component: React.lazy(() =>
      import("views/app-views/apps/restaurant/edit-restaurant")
    ),
  },
  {
    key: "list-restaurant",
    path: `${APP_PREFIX_PATH}/apps/restaurant/list-restaurant`,
    component: React.lazy(() =>
      import("views/app-views/apps/restaurant/list-restaurant")
    ),
  },
];

export const appRoutes = [...tourRoutes, ...hotelRoutes, ...restaurantRoutes];

export const protectedRoutes = [
  ...appRoutes,
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },
  {
    key: "partner-list",
    path: `${APP_PREFIX_PATH}/apps/user/partner-list`,
    component: React.lazy(() =>
      import("views/app-views/apps/user/partner-list")
    ),
  },
  {
    key: "tourist-list",
    path: `${APP_PREFIX_PATH}/apps/user/tourist-list`,
    component: React.lazy(() =>
      import("views/app-views/apps/user/tourist-list")
    ),
  },

  {
    key: "event-list",
    path: `${APP_PREFIX_PATH}/apps/events/event-list`,
    component: React.lazy(() =>
      import("views/app-views/apps/events/event-list")
    ),
  },
  {
    key: "add-event",
    path: `${APP_PREFIX_PATH}/apps/events/add-event`,
    component: React.lazy(() =>
      import("views/app-views/apps/events/add-event")
    ),
  },
  {
    key: "edit-event",
    path: `${APP_PREFIX_PATH}/apps/events/edit-event/:eventId`,
    component: React.lazy(() =>
      import("views/app-views/apps/events/edit-event")
    ),
  },

  {
    key: "add-entertainment",
    path: `${APP_PREFIX_PATH}/apps/entertainment/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/entertainment/add")
    ),
  },
  {
    key: "edit-entertainment",
    path: `${APP_PREFIX_PATH}/apps/entertainment/edit/:entertainmentId`,
    component: React.lazy(() =>
      import("views/app-views/apps/entertainment/edit")
    ),
  },
  {
    key: "list-entertainment",
    path: `${APP_PREFIX_PATH}/apps/entertainment/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/entertainment/list")
    ),
  },
  {
    key: "add-attraction",
    path: `${APP_PREFIX_PATH}/apps/attraction/add`,
    component: React.lazy(() => import("views/app-views/apps/attraction/add")),
  },
  {
    key: "edit-attraction",
    path: `${APP_PREFIX_PATH}/apps/attraction/edit/:attractionId`,
    component: React.lazy(() => import("views/app-views/apps/attraction/edit")),
  },
  {
    key: "list-attraction",
    path: `${APP_PREFIX_PATH}/apps/attraction/list`,
    component: React.lazy(() => import("views/app-views/apps/attraction/list")),
  },
  {
    key: "add-blog",
    path: `${APP_PREFIX_PATH}/apps/blog/add`,
    component: React.lazy(() => import("views/app-views/apps/blog/add")),
  },
  {
    key: "edit-blog",
    path: `${APP_PREFIX_PATH}/apps/blog/edit/:blogId`,
    component: React.lazy(() => import("views/app-views/apps/blog/edit")),
  },
  {
    key: "list-blog",
    path: `${APP_PREFIX_PATH}/apps/blog/list`,
    component: React.lazy(() => import("views/app-views/apps/blog/list")),
  },

  {
    key: "add-region",
    path: `${APP_PREFIX_PATH}/apps/region/add`,
    component: React.lazy(() => import("views/app-views/apps/region/add")),
  },
  {
    key: "edit-region",
    path: `${APP_PREFIX_PATH}/apps/region/edit/:regionId`,
    component: React.lazy(() => import("views/app-views/apps/region/edit")),
  },
  {
    key: "list-region",
    path: `${APP_PREFIX_PATH}/apps/region/list`,
    component: React.lazy(() => import("views/app-views/apps/region/list")),
  },
  {
    key: "registry-tour-operator",
    path: `${APP_PREFIX_PATH}/apps/registry/tour-operators`,
    component: React.lazy(() =>
      import("views/app-views/apps/registry/tour-operators")
    ),
  },
  {
    key: "registry-restaurant",
    path: `${APP_PREFIX_PATH}/apps/registry/restaurants`,
    component: React.lazy(() =>
      import("views/app-views/apps/registry/restaurants")
    ),
  },
  {
    key: "registry-hotel",
    path: `${APP_PREFIX_PATH}/apps/registry/hotels`,
    component: React.lazy(() => import("views/app-views/apps/registry/hotels")),
  },
  {
    key: "registry-tour",
    path: `${APP_PREFIX_PATH}/apps/registry/tours`,
    component: React.lazy(() => import("views/app-views/apps/registry/tours")),
  },
  {
    key: "registry-attraction",
    path: `${APP_PREFIX_PATH}/apps/registry/attractions`,
    component: React.lazy(() =>
      import("views/app-views/apps/registry/attractions")
    ),
  },
  {
    key: "review-list",
    path: `${APP_PREFIX_PATH}/apps/reviews/list`,
    component: React.lazy(() => import("views/app-views/apps/reviews/list")),
  },
];

import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";

import {
  AUTH_PREFIX_PATH,
  UNAUTHENTICATED_ENTRY,
  REDIRECT_URL_KEY,
  APP_PREFIX_PATH,
  AUTHENTICATED_ENTRY,
} from "configs/AppConfig";

const ProtectedRoute = () => {
  const location = useLocation();
  const { token } = useSelector((state) => state.auth);
  const decodedToken = token ? jwtDecode(token) : null;
  if (!token) {
    return (
      <Navigate
        to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`}
        replace
      />
    );
  }
  if (AUTHENTICATED_ENTRY === location.pathname) {
    return <Outlet />;
  }
  switch (decodedToken.PartnerType) {
    case "tour":
      if (
        !location.pathname.includes("tours") &&
        location.pathname.includes(AUTHENTICATED_ENTRY)
      ) {
        return <Navigate to={`${APP_PREFIX_PATH}/app/tours/tour-list`} />;
      }
      break;
    case "hotel":
      if (
        !location.pathname.includes("hotels") &&
        location.pathname.includes(AUTHENTICATED_ENTRY)
      ) {
        return <Navigate to={`${APP_PREFIX_PATH}/app/hotels/hotel-list`} />;
      }
      break;
    case "restaurant":
      if (
        !location.pathname.includes("restaurant") &&
        location.pathname.includes(AUTHENTICATED_ENTRY)
      ) {
        return <Navigate to={`${APP_PREFIX_PATH}/app/restaurant`} />;
      }
      break;
    case "region":
      if (
        !location.pathname.includes("region") &&
        location.pathname.includes(AUTHENTICATED_ENTRY)
      ) {
        return <Navigate to={`${APP_PREFIX_PATH}/app/region/list`} />;
      }
      break;
  }
  return <Outlet />;
};

export default ProtectedRoute;

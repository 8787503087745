import React from "react";
import { Routes as RouterRoutes, Route, Navigate } from "react-router-dom";
import {
  AUTHENTICATED_ENTRY,
  AUTHENTICATED_HOTEL_ENTRY,
  AUTHENTICATED_REGION_ENTRY,
  AUTHENTICATED_RESTAURANT_ENTRY,
  AUTHENTICATED_TOUR_ENTRY,
} from "configs/AppConfig";
import {
  protectedRoutes,
  publicRoutes,
  tourRoutes,
} from "configs/RoutesConfig";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import AppRoute from "./AppRoute";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { useLocation } from "react-router-dom";
import AutoLogin from "views/app-views/apps/user/auto-login";
const Routes = () => {
  const { token } = useSelector((state) => state.auth);
  const location = useLocation();

  const decodedToken =
    token && token?.split(".")?.length >= 2 ? jwtDecode(token) : null;

  function handlerAuthenticatedRoute(decodedToken, location) {
    // Define mappings for partner types to expected path segments
    const pathMappings = {
      tour: "tour",
      hotel: "hotel",
      restaurant: "restaurant",
      region: "region",
    };

    // Redirect paths based on partner type
    const redirectPath = {
      tour: AUTHENTICATED_TOUR_ENTRY,
      hotel: AUTHENTICATED_HOTEL_ENTRY,
      restaurant: AUTHENTICATED_RESTAURANT_ENTRY, // Ensure this is correct
      region: AUTHENTICATED_REGION_ENTRY,
    };

    // Use optional chaining to safely access properties
    const partnerType = decodedToken?.PartnerType;
    const partnerTypePath = pathMappings[partnerType];
    if (
      partnerTypePath &&
      location.pathname &&
      !location.pathname.includes(partnerTypePath)
    ) {
      return redirectPath[partnerType];
    }
  }

  let initialRedirectPath = AUTHENTICATED_ENTRY; // Default entry path for authenticated users
  if (decodedToken) {
    const roleBasedRedirect = handlerAuthenticatedRoute(decodedToken, location);
    if (roleBasedRedirect) {
      initialRedirectPath = roleBasedRedirect;
    }
  }
  return (
    <RouterRoutes>
      <Route path="/" element={<ProtectedRoute />}>
        <Route
          path="/"
          element={<Navigate replace to={initialRedirectPath} />}
        />

        {protectedRoutes.map((route, index) => {
          return (
            <Route
              key={route.key + index}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          );
        })}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
      <Route path="/auto-login/:queryToken" element={<AutoLogin />} />
      <Route path="/" element={<PublicRoute />}>
        {publicRoutes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          );
        })}
      </Route>
    </RouterRoutes>
  );
};

export default Routes;

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { 
  AUTHENTICATED_ENTRY, 
  AUTHENTICATED_TOUR_ENTRY, 
  AUTHENTICATED_HOTEL_ENTRY, 
  AUTHENTICATED_REGION_ENTRY, 
  AUTHENTICATED_RESTAURANT_ENTRY
} from 'configs/AppConfig';
import { jwtDecode } from 'jwt-decode';

const PublicRoute = () => {
  const { token } = useSelector((state) => state.auth);

  // Function to decode the token and redirect based on partnerType
  const getRedirectPath = (token) => {
    try {
      const { PartnerType } = jwtDecode(token);
      switch (PartnerType) {
        case 'tour':
          return AUTHENTICATED_TOUR_ENTRY;
        case 'hotel':
          return AUTHENTICATED_HOTEL_ENTRY;
        case 'region':
          return AUTHENTICATED_REGION_ENTRY;
		case 'restaurant':
		  return AUTHENTICATED_RESTAURANT_ENTRY;
        default:
          return AUTHENTICATED_ENTRY;
      }
    } catch (error) {
      console.error('Error decoding token:', error);
      // Consider a fallback or error handling strategy here
      return AUTHENTICATED_ENTRY;
    }
  };

  return token ? <Navigate to={getRedirectPath(token)} /> : <Outlet />;
};

export default PublicRoute;
